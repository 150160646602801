import { ScrollToTop, NotFound } from "@curaleaf-international/components";
import { Route, Switch } from "wouter";

const Router = () => (
  <>
    <ScrollToTop />
    <Switch>
      <Route path="/"></Route>
      <Route path="/(.*)">
        <NotFound />
      </Route>
    </Switch>
  </>
);

export default Router;
