import { Error } from "@curaleaf-international/components";
import { browserTracingIntegration } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "src/App";

export const commitHash =
  document
    .querySelector("meta[data-commit-hash]")
    ?.getAttribute("data-commit-hash") ?? "unknown";

export const sentryDsn = document
  .querySelector("meta[data-sentry-dsn]")
  ?.getAttribute("data-sentry-dsn");

if (import.meta.env.PROD && sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    release: commitHash,
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 0.01,
  });
}

axios.defaults.timeout = 15000;

createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={Error}>
      <App />
    </Sentry.ErrorBoundary>
  </StrictMode>,
);
