import "@fontsource/inter";
import {
  ToastContextProvider,
  Toasts,
} from "@curaleaf-international/components";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Router from "src/Router";
import ThemeProvider from "src/ThemeProvider";

const App = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Curaleaf Clinic</title>
      </Helmet>
      <ThemeProvider>
        <ToastContextProvider>
          <Toasts />
          <Router />
        </ToastContextProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
